import { type FC, type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { useResource, type ResourceType, type Serializer } from 'core'
import { photoSerializer } from 'resources/gallery'

import { deleteJobActionParams } from '../actions/delete'
import { type JobModel } from '../types'

import JobForm from './JobForm'

// 'view' displays only disabled inputs
// 'readonly' displays disabled inputs together with other disabled actions
type Mode = 'view' | 'readonly'

export interface JobDrawerTogglerProps {
    id?: Identifier
    resource?: ResourceType
    children: (params: () => void) => ReactElement
    mode?: Mode
    billable?: boolean
}

const JobDrawerToggler: FC<JobDrawerTogglerProps> = ({
    children,
    id,
    resource: resourceProp,
    mode,
    billable,
}) => {
    const open = useOpenUtilityDrawer()

    const readOnly = mode === 'view' || mode === 'readonly'
    const resource = useResource(resourceProp)

    return children(() => {
        open({
            drawerArgs: {
                title: readOnly ? 'View Job' : id ? 'Edit Job' : 'Create Job',
                renderTopRight:
                    mode === 'view' || !id
                        ? undefined
                        : (render) =>
                              render({
                                  ...deleteJobActionParams(id, resource),
                                  disabled: readOnly,
                              }),
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={woJobSerializer}
                    />
                ),
                renderBottomRight: readOnly ? () => null : undefined,
                renderBottomLeft: readOnly
                    ? (renderCancelButton) => renderCancelButton({ children: 'Close' })
                    : undefined,
                renderContent: () => (
                    <FormContent
                        readOnly={readOnly}
                        id={id}
                        billable={billable}
                    />
                ),
            },
            extraArgs: {
                resource,
                id,
            },
            // Keep this for photos input
            extra: { disabledFields: readOnly },
        })
    })
}

const FormContent: FC<{ readOnly?: boolean; id: Identifier; billable: boolean }> = ({
    id,
    readOnly,
    billable,
}) => {
    const record = useRecordContext<JobModel>()

    if (id && !record) {
        return null
    }

    return (
        <JobForm
            isInputDisabled={() => readOnly}
            isHidden={(source) => {
                if (!billable && source === 'billable') {
                    return true
                }
            }}
        />
    )
}

export default JobDrawerToggler

export const woJobSerializer: Serializer<JobModel> = [
    'name',
    'complaint',
    { name: 'billable', parse: 'boolean' },
    { name: 'notesToAdd', parse: 'emptyToNull' },
    { name: 'component', parse: 'emptyToNull' },
    { name: 'reasonForRepair', parse: 'emptyToNull' },
    'description',
    ...(photoSerializer as Serializer<JobModel>),
]
