import { useRef, type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { ListBase } from 'components'
import { HEAVY_HAUL_SOLUTIONS_ID, urls } from 'configs'
import { useArchivedContext, ArchivedContext } from 'context'
import { type AuthStore, removeKey, type SortPayload } from 'core'
import { useLocation, useNavigate } from 'lib'
import {
    type UnitModel,
    unitAspects,
    unitNumberSort,
    unitResource,
    UnitsListBase,
} from 'resources/units'
import { PageContent } from 'ui'
import { displayBooleanValue } from 'utils'

import { UnitsListHeader } from './components'

const defaultUnitSort: SortPayload<UnitModel> = {
    field: 'created',
    order: 'DESC',
}

const defaultArchivedUnitSort: SortPayload<UnitModel> = {
    field: 'archived',
    order: 'DESC',
}

const UnitsListPage: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { isArchived } = useArchivedContext()

        // Remove the stored list config when navigating (switch or sidebar) between archived and non-archived units
        // TODO: Find better way to handle this
        const prevIsArchived = useRef(isArchived)
        if (prevIsArchived.current !== isArchived) {
            removeKey(unitResource.resource)
            prevIsArchived.current = isArchived
        }

        const defaultSort =
            auth.currentCompanyId === HEAVY_HAUL_SOLUTIONS_ID
                ? unitNumberSort
                : isArchived
                  ? defaultArchivedUnitSort
                  : defaultUnitSort

        return (
            <ListBase
                sort={defaultSort}
                key={String(isArchived)}
                filter={{
                    withAspects: [unitAspects.costPerMeter, unitAspects.totals],
                    archived: displayBooleanValue(isArchived),
                }}
            >
                <UnitsListHeader isArchived={isArchived} />
                <PageContent>
                    <UnitsListBase />
                </PageContent>
            </ListBase>
        )
    }),
)

const UnitsList: FC = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <ArchivedContext.Provider
            value={{
                isArchived: pathname === urls.unitsArchived,
                setIsArchived: (toggle: boolean) => {
                    navigate(toggle ? urls.unitsArchived : urls.units)
                },
            }}
        >
            <UnitsListPage />
        </ArchivedContext.Provider>
    )
}

export default UnitsList
